export default {
	// api请求地址
	baseUrl: 'https://cs.b2c.niuteam.cn',
	// 图片域名
	imgDomain: 'https://cs.b2c.niuteam.cn',
	// 前端域名
	webDomain: 'https://cs.b2c.niuteam.cn/web',
	// 腾讯地图key
	mpKey: '6ZDBZ-CLSLX-66747-7MVM4-HLK47-XMBXU',
	// 客服
	webSocket: 'wss://cs.b2c.niuteam.cn/wss',
	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500
}